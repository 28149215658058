// init as external plugin
window.KolesoWidget = {
  init: ({
    token,
    uuid,
    regionFias,
    partner = "",
    safeMode,
    cookie,
    styles = {
      "z-index": "99999999 !important",
      position: "relative",
    },
    onload = () => {},
  }) => {
    ;(function (w, d, widget, link, script, p) {
      // widget env
      const widgetURL = process.env.VUE_APP_URL

      let styleString = ""
      for (let style in styles) {
        styleString += style + ":" + styles[style] + ";"
      }

      // widget create
      widget = d.createElement("koleso-widget")
      widget.setAttribute("token", token)
      widget.setAttribute("uuid", uuid)
      widget.setAttribute("region-fias", regionFias)
      widget.setAttribute("partner", partner)
      widget.setAttribute("safe-mode", safeMode)
      widget.setAttribute("cookie", cookie)
      widget.setAttribute("style", styleString)

      // widget style add
      d.getElementsByTagName("body")[0].appendChild(widget)
      link = d.createElement("link")
      link.rel = "stylesheet"
      link.href = widgetURL + "/css/index.css?" + process.env.VUE_APP_VER
      d.getElementsByTagName("head")[0].appendChild(link)
      // widget script add
      script = d.createElement("script")
      script.async = true
      script.src = widgetURL + "/js/index.js?" + process.env.VUE_APP_VER
      d.getElementsByTagName("body")[0].appendChild(script)
      script.onload = () => {
        onload()
      }
    })(window, document)
  },
}
